.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-links {
  margin-left: 20;
  display: "flex"
}

.link {
  text-decoration: "none";
  color: "white";
  font-size: "20px";
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
  line-height: 46px;
}

.link:hover {
    color: "yellow";
    border-bottom: 1px solid white;
    background-color: #282c34;
}

#helper-area  p{
    margin: 0px;
}
code {
    background-color: #eee;
    border-radius: 3px;
    font-family: courier, monospace;
    padding: 0 3px;
}
:root {
    box-sizing: border-box;  
  }
  
  *,
  ::before,
  ::after {
    box-sizing: inherit; 
  }
